import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/pageTemplate.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`While working on `}<a parentName="p" {...{
        "href": "http://thumbsupnews.net"
      }}>{`Thumbs Up News`}</a>{`, I've noticed a strange UI bug that happened only on Safari, when the screen was smaller than 900px height-wise. All the other browsers where okay, but the subscribe button was broken. The border was pushed up outside the area of the button and was now between the two elements of the menu. This only happened when a user clicked the `}<em parentName="p">{`categories`}</em>{` button and the sub-menu expanded. I'm assuming that this happens because Apple wants space for its bottom menu?`}</p>
    <p>{`The mobile menu is set on a fixed position. It seems that Safari will change either the padding, the margin or just some border rules when that fixed element doesn't have enough space vertically to show some space under the last element. My first thought was to use the `}<inlineCode parentName="p">{`-webkit`}</inlineCode>{` rule to increase the padding on the button. Using that rule fixed the issue on Safari but broke on Chrome and this is when my search on how to apply CSS rules to a specific browser started.`}</p>
    <p>{`After searching for a while, I've come across this article written by Ryan - `}<a parentName="p" {...{
        "href": "https://www.ryadel.com/en/css3-media-query-target-only-ie-ie6-ie11-firefox-chrome-safari-edge/"
      }}>{`CSS3 Media Query to target only Internet Explorer (from IE6 to IE11+), Firefox, Chrome, Safari and/or Edge`}</a>{`, if you scroll down you come to this little hack that works on Safari 10.1+.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`@media not all and (min-resolution:.001dpcm) {
    @media {
        .safari10 {
        color:#0000FF;
        background-color:#CCCCCC;
        }
    }
}
`}</code></pre>
    <p>{`I am using Sass on Thumbs Up News and that means I can't have an empty `}<inlineCode parentName="p">{`@media`}</inlineCode>{` rule. But this was a great starting point for coming up with a solution. I've removed the empty media query to see if the rule would work on Safari and not on any other browser. Surprise, surprise, it worked! Now I decided to play around with the media query rule a bit more, to see what works and what doesn't work. `}</p>
    <p>{`The Safari only rule hack has two parts. You need to use a unit that is not supported by Safari - `}<inlineCode parentName="p">{`dpcm`}</inlineCode>{`, `}<inlineCode parentName="p">{`dpi`}</inlineCode>{`, `}<inlineCode parentName="p">{`dppx`}</inlineCode>{` and you need to set that media query to `}<inlineCode parentName="p">{`not all`}</inlineCode>{`. The `}<em parentName="p">{`not all`}</em>{` rule is important so chrome doesn't pick it up.`}</p>
    <h2 {...{
      "id": "the-solution",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#the-solution",
        "aria-label": "the solution permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`The solution`}</h2>
    <p>{`After testing different things and playing around with the media query, I've come to the final solution. I've also added a rule to trigger the CSS only when the screen is smaller than 900px high, because we don't want the menu to be broken on a larger screen.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`@media not all and (max-height: 899px) and (min-resolution:.1dpi) {
    .safari-only {
    -webkit-padding-after: 1.7rem;
    }
}
`}</code></pre>
    <p>{`That's all there is to get a media query work on Safari only. I'm still wondering why this trick works and would love to know why, so if you know the answer please let me know!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      